import React from 'react';

import Layout from '../../../components/MenuLayout';

import { UserPlan } from '../../../components/User/UserPlan';

export default () => (
  <Layout type="userSettings">
    <UserPlan />
  </Layout>
);
