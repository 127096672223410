import React, { useContext } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import { ThemeContext } from 'styled-components';
import { SliderWrapper } from './styled/SliderWrapper';

const useStyles = makeStyles(() => {
  const themeContext = useContext(ThemeContext);
  return ({
    container: {
      display: 'flex',
      marginBottom: '.125em',
    },
    label: {
      fontSize: '.9375em',
      color: themeContext.colors.gray800,
    },
    details: {
      marginLeft: 'auto !important',
      fontSize: '.9375em',
      color: themeContext.colors.gray800,
    },
  });
});

export const Slider = ({ label, value, max, prettify }) => {
  const valuePercent = `${(value * 100) / max}%`;
  const valueDisplay = prettify ? prettify(value).string : value;
  const maxDisplay = prettify ? prettify(max).string : max;
  const classes = useStyles();

  return (
    <SliderWrapper>
      <div className={classes.container}>
        <div className={classes.label}>{label}</div>
        <div className={classes.details}>{`${valueDisplay} of ${maxDisplay}`}</div>
      </div>
      <div className="slider-container">
        <div className="slider-value" style={{ width: valuePercent }} />
      </div>
    </SliderWrapper>
  );
};
