import styled from 'styled-components';

export const SliderWrapper = styled.div`
  margin-bottom: .625em;

  .slider-container {
    height: 8px;
    background-image: linear-gradient(90deg, ${(props) => props.theme.colors.gray200}, ${(props) => props.theme.colors.gray600});
  }
  .slider-value {
    max-width: 100%;
    height: 8px;
    background-color: ${(props) => props.theme.colors.primary};
    
  }
`;
