import React, { useContext } from 'react';

import { useTranslation } from 'react-i18next';
import { useQuery } from '@apollo/react-hooks';
import { Link } from 'gatsby';

import { makeStyles } from '@material-ui/core/styles';
import { Box, Container } from '@material-ui/core';
import { ThemeContext } from 'styled-components';
import { Navbar } from '../Navbar/styled/NavbarStyles';
import { Slider } from '../Common/Slider/Slider';
import Loading from '../Common/Loading';

import { GET_USER_BILLING_PLAN } from '../../graphql/queries';
import { KBytesPrettify } from '../../utils/BytesPrettifyUtils';
import SectionAccordion from '../../componentsUI/SectionAccordion';
import { HighlightedText } from '../Common/styled/HighlightedText';
import { ButtonUI } from '../../componentsUI/Button';
import { getFormattedDate } from '../../utils/dateTimeUtils';
import { ScrollableContainer } from '../Common/ScrollableContainer';
import { SectionBar } from '../../componentsUI/SectionBar';

const useStyles = makeStyles(() => {
  const themeContext = useContext(ThemeContext);
  return ({
    container: {
      maxWidth: '100%',
      padding: '.75em 2.5em',
      '@media (max-width:600px)': {
        padding: '.75em 1.5em',
      },
    },
    details: {
      margin: '.375em 0 0',
      fontSize: '.9375em',
      color: themeContext.colors.gray800,
    },
    detailsTitle: {
      margin: '0 .5em .5em',
    },
    billingDate: {
      margin: '0',
      color: themeContext.colors.gray800,
    },
  });
});

export const UserPlan = () => {
  const { loading, data } = useQuery(GET_USER_BILLING_PLAN);
  const { t } = useTranslation();
  const classes = useStyles();

  const DetailsItem = ({ className }) => (
    <div className={className}>
      <h4 className={classes.detailsTitle}>
        {data.userBillingPlan.billingPlan.name}
      </h4>
      <p className={classes.details}>
        {`${data.userBillingPlan.billingPlan.users} ${t('users.for.your.hospitals')}`}
      </p>
      <p className={classes.details}>
        {`${KBytesPrettify(data.userBillingPlan.billingPlan.storage).string} ${t('data.storage')}`}
      </p>
      <p className={classes.details}>
        {`${KBytesPrettify(data.userBillingPlan.billingPlan.dicomDownload).string} ${t('dicom.download')}`}
      </p>
      <p className={classes.details}>
        {`${data.userBillingPlan.billingPlan.credits} ${t('credits')}`}
      </p>
      {data.userBillingPlan.billingPlan.supportHours > 0
      && (
        <p className={classes.details}>
          {`${data.userBillingPlan.billingPlan.supportHours} ${t('support.hours')}`}
        </p>
      )}
    </div>
  );

  const UsageItem = ({ className }) => (
    <div className={className}>
      <Slider
        label={t('users')}
        value={data.userBillingPlan.consumedUsers}
        max={data.userBillingPlan.billingPlan.users}
      />
      <Slider
        label={t('storage')}
        value={data.userBillingPlan.consumedStorage}
        max={data.userBillingPlan.billingPlan.storage}
        prettify={KBytesPrettify}
      />
      <Slider
        label={t('dicom.download')}
        value={data.userBillingPlan.consumedDicomDownload}
        max={data.userBillingPlan.billingPlan.dicomDownload}
        prettify={KBytesPrettify}
      />
      {data.userBillingPlan.billingPlan.credits > 0
        && (
          <Slider
            label={t('credits')}
            value={data.userBillingPlan.consumedCredits}
            max={data.userBillingPlan.billingPlan.credits}
          />
        )}
      {data.userBillingPlan.billingPlan.supportHours > 0
        && (
          <Slider
            label={t('hours')}
            value={data.userBillingPlan.consumedSupportHours}
            max={data.userBillingPlan.billingPlan.supportHours}
          />
        )}
    </div>
  );

  const BillingItem = ({ className }) => (
    <div className={className}>
      <p className={classes.billingDate}>
        {`${t('next.billing.date')}: `}
        <strong>
          {data && data.userBillingPlan && getFormattedDate({ date: data.userBillingPlan.nextBillingDate })}
        </strong>
      </p>
      {data.userBillingPlan.nextBillingPlan
        ? (
          <>
            <p className="my-1">
              {`${t('next.billing.plan')}: `}
              <HighlightedText>
                <strong>
                  {data.userBillingPlan.nextBillingPlan.name}
                </strong>
              </HighlightedText>
            </p>
            <p className="my-1">
              {`${t('amount')}: `}
              <HighlightedText>
                <strong>
                  {`${data.userBillingPlan.nextBillingPlan.price}€`}
                </strong>
              </HighlightedText>
            </p>
          </>
        ) : (
          <h4>Warning: Next billing plan is not configured</h4>
        )}
      <div className="d-flex justify-content-end mb-2">
        <Link to="/plans">
          <ButtonUI variant="contained" color="primary" size="large">
            {t('change.plan')}
          </ButtonUI>
        </Link>
      </div>
    </div>
  );

  return (
    <>
      <Navbar className={classes.subheader}>
        <SectionBar title="my.plan" />
      </Navbar>

      <Box className={classes.container}>
        {loading || !data ? <Loading /> : (
          <ScrollableContainer padding="24px 6px">
            <Container maxWidth="md">
              <SectionAccordion title={t('plan.details')} Item={DetailsItem} expanded />
              <SectionAccordion title={t('plan.usage')} Item={UsageItem} />
              {/* <SectionAccordion title={t('billing.details')} Item={BillingItem} /> */}
            </Container>
          </ScrollableContainer>
        )}
      </Box>
    </>
  );
};
